<template>
  <div class="animated fadeIn">
    <loading :active.sync="isLoading"></loading>
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1 class="h3">{{$t('message.registration_confirmation')}}</h1>
            <p class="lead">
              {{$t('message.request_new_confirmation_token')}}
            </p>
          </div>
          <form @submit.prevent="submit">
            <b-row>
              <b-col sm="12">
                <validation-field
                  :label="$t('message.email')"
                  :v="$v.form.email"

                >
                  <input
                    class="form-control"
                    maxlength="50" type="text"
                    v-model="form.email"
                    v-on:blur="$v.form.email.$touch()"
                  >
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" sm="12">
                <b-button :class="{invalid:$v.form.$invalid}" block
                          type="submit" class="bg-theme-secondary">
                  {{$t('message.send_request')}}
                </b-button>
              </b-col>
            </b-row>
          </form>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

  import ApiResolver from '../components/common/ApiResolver';
  import Api from '../components/common/Api';
  import {email, required} from 'vuelidate/lib/validators'
  import ValidationField from "../components/ui/ValidationField";
  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name: "SendRegistrationToken",
    components: {
      ValidationField,
      Loading
    },
    data() {
      return {
        form: {
          email: null
        },
        isLoading: false
      }
    },
    validations: {
      form: {
        email: {
          required: required,
          email
        }
      }
    },
    methods: {
      submit() {

        this.$v.form.$touch();
        if (this.$v.form.$invalid) return;
        // to form submit after this

        this.isLoading = true;

        Api
          .post(ApiResolver.get('SEND_REGISTRATION_TOKEN'), JSON.stringify(this.form))
          .then(response => {

            this.isLoading = false;

            this.$store.dispatch('alerts/setAlert', {
              type: 'success',
              text: this.$t('message.check_mail_for_token')
            });

            /** redirect alla login **/
            this.$router.push({name:'login',params: {}})


          })
          .catch(reason => {

            this.isLoading = false;

            this.$store.dispatch('alerts/setAlert', {
              type: 'danger',
              text: this.$t(reason.title) + ' : ' + this.$t(reason.detail)
            });
          });
      },
    },
    mounted() {

    }
  }
</script>

<style scoped>
  .hasError label {
    color: red;
  }

  .error {
    color: red
  }
</style>
